import CloseIcon from '@mui/icons-material/Close'
import { Box, BoxProps, Container, IconButton, Typography } from '@mui/material'
import { domAnimation, LazyMotion, m } from 'framer-motion'
import Link from 'next/link'
import { memo, useEffect, useMemo, useRef } from 'react'
// import { useSessionStorage } from 'react-use'
// import { getAnnouncementBarConf } from 'src/api/common'
import useCurrentMediaQuery from 'src/hooks/useCurrentMediaQuery'
import SwiperCore, { Mousewheel } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
// import useSwr from 'swr'

SwiperCore.use([Mousewheel])

const announcementBarWrapperVariants = {
  visible: {
    height: 'auto',
    // backgroundColor: 'rgba(0, 81, 207, 1)',
    transition: {
      ease: [0.39, 0, 0.23, 1],
    },
  },
  hidden: {
    height: 0,
    // backgroundColor: 'rgba(0, 81, 207, 0)',
  },
}

// const sessionStorageKey = '__IS_HIDE_ANNOUNCEMENT_BAR_2023__'

const AnnouncementBar = (
  props: {
    window?: () => Window
    onShow?: (height: number) => void
  } & BoxProps
) => {
  const currentMediaQuery = useCurrentMediaQuery()
  // const [oauthToken] = useCookie('__oauth_token__')
  const { sx, onShow } = props
  const wrapperRef = useRef<HTMLDivElement>(null)
  // const [isHideAnnouncementBar] = useSessionStorage(sessionStorageKey, '')
  // const [isHideAnnouncementBar, setAnnouncementBar] = useState(false)

  // const { data } = useSwr(
  //   { url: '/api/getAnnouncementBarConf', token: oauthToken as string },
  //   async ({ token }) => {
  //     if (!token) return
  //     return getAnnouncementBarConf()
  //   },
  //   { revalidateOnFocus: true }
  // )

  const isShowAnnouncementBar = useMemo(
    () => false,
    // () => !isHideAnnouncementBar,
    // !isHideAnnouncementBar &&
    // data?.data?.home?.advertise?.showHomeHeadActivity,
    // () => !isHideAnnouncementBar,
    []
  )

  // const hide = () => sessionStorage.setItem(sessionStorageKey, 'true')
  const hide = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault()
    // setAnnouncementBar('true')
  }

  useEffect(() => {
    if (onShow && wrapperRef.current) {
      const height = currentMediaQuery === 'xs' ? 64 : 40
      onShow?.(isShowAnnouncementBar ? height : 0)
    }
  }, [onShow, isShowAnnouncementBar, currentMediaQuery])

  // useEffect(() => {
  //   const isHideAnnouncementBar = sessionStorage.getItem(sessionStorageKey)
  //   if (isHideAnnouncementBar && isInit.current === false) {
  //     setAnnouncementBar(true)
  //   }
  //   isInit.current = true
  // }, [setAnnouncementBar])

  return (
    <LazyMotion features={domAnimation}>
      <Box
        component={m.div}
        variants={announcementBarWrapperVariants}
        initial="hidden"
        animate={isShowAnnouncementBar ? 'visible' : 'hidden'}
        sx={{
          position: 'relative',
          width: '100%',
          overflow: 'hidden',
          zIndex: 1109,
          background:
            'linear-gradient(94.53deg, #A3404C 5%, #E99DA4 28%, #A3404C 50%, #E99DA4 74%, #A3404C 99%);',
          // 'linear-gradient(157deg, rgba(197, 151, 15, 1) 4%, rgba(251, 210, 89, 1) 25%, rgba(197, 151, 15, 1) 50%, rgba(251, 210, 89, 1) 75%, rgba(197, 151, 15, 1) 99%)',
          ...sx,
        }}
        ref={wrapperRef}
      >
        <Box sx={{ position: 'relative' }}>
          <Box
            sx={{
              width: '100%',
              overflow: 'hidden',
              // position: 'absolute',
              position: 'relative',
              height: '100%',
            }}
          >
            <Swiper
              // cssMode={true}
              modules={[Mousewheel]}
              slidesPerView={1}
              loop={false}
              effect={'fade'}
              observer={true}
              observeParents={true}
              loopedSlides={7}
              autoplay={{
                delay: 6000,
                stopOnLastSlide: false,
                disableOnInteraction: true,
              }}
              style={{
                position: 'relative',
                zIndex: 99,
              }}
            >
              <SwiperSlide>
                <Box
                  sx={{
                    py: {
                      xs: '10px',
                      lg: '14px',
                    },
                    cursor: 'pointer',
                    display: 'block',
                  }}
                  component={Link}
                  href="https://us.dreo.com/pages/2024-valentine-day-sale?utm_source=dreo_offical_website&utm_medium=announcement&utm_campaign=2024-valentine-day-sale"
                >
                  <Container maxWidth="xl">
                    <Box position="relative">
                      <Typography
                        component={m.p}
                        variants={{
                          visible: {
                            color: 'rgba(255,255,255, 1)',
                          },
                          hidden: {
                            color: 'rgba(255,255,255, 0)',
                          },
                        }}
                        initial="hidden"
                        animate="visible"
                        sx={{
                          fontSize: 12,
                          fontWeight: 700,
                          textAlign: 'center',
                          fontFamily: '"Manrope",sans-serif',
                          lineHeight: 1.7,
                          // letterSpacing: '0.001em',
                          '&:hover': {
                            '& span': {
                              textDecoration: 'underline',
                            },
                          },
                        }}
                      >
                        {/* ChefMaker Early Fall Sales - 10% Off ｜ Buy Now */}
                        {/* Up To 45% OFF! DREO New Year Deals. Shop Now &gt;&gt; */}
                        Up To 30% OFF! Valentine's Day Sale. Shop Now &gt;&gt;
                      </Typography>
                      <Box
                        sx={{
                          position: 'absolute',
                          right: 0,
                          top: '50%',
                          transform: 'translateY(-50%)',
                          zIndex: 100,
                        }}
                      >
                        <IconButton
                          disableRipple
                          size="small"
                          edge="start"
                          aria-label="close announcement bar"
                          onClick={hide}
                        >
                          <CloseIcon sx={{ color: 'common.white' }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Container>
                </Box>
              </SwiperSlide>
            </Swiper>
          </Box>
        </Box>
      </Box>
    </LazyMotion>
  )
}

export default memo(AnnouncementBar)
